(function ($) {
  $(function () {
    //region Общее
    //region Отменяю стандартное действие
    $('.js-prevent').on('click', function (e) {
      e.preventDefault();
    });
    //endregion

    //region Focus/unfocus на инпутах
    $('input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea').on('mouseover', function () {
      $(this).focus();
      moveCursorToEnd($(this));
    });
    //endregion

    $('.header__burger-icon').on('click', function () {
      $(this).toggleClass('js-active');
      $('.main-menu').toggleClass('js-active');
    });
    //endregion


    //region Поиск
    $('.header__search').on('click', function (e) {
      if ($(e.target).hasClass('header__search-close')) {
        return
      }
      $(this).addClass('js-active');
    });
    $('.header__search-close').on('click', function () {
      $('.header__search').removeClass('js-active');
    });
    //endregion


    //region ТОВАР
    //region Табы
    $('.tabs__title').on('click', function () {
      var $this = $(this),
        $index = $(this).index(),
        $parent = $this.parents('.tabs'),
        $item = $parent.find('.tabs__item').eq($index);

      $item.addClass('js-active').siblings().removeClass('js-active');
      $this.addClass('js-active').siblings().removeClass('js-active');
    });
    //endregion

    //region Подгрузка отзывов
    var reviewsLength = $(".reviews__item").length;
    var reviewsPart = 6;
    $('.reviews__load').on('click', function () {
      var $this = $(this);

      reviewsPart = (reviewsPart + 6 <= reviewsLength) ? reviewsPart + 6 : reviewsLength;
      $('.reviews__item:lt(' + reviewsPart + ')').fadeIn();
      if (reviewsPart === reviewsLength) {
        $this.addClass('js-hidden');
      }
    });
    //endregion

    //region Изменение количества товара
    $(document).on('click', '.qty__btn', function () {
      var $this = $(this),
        $action = $this.data('action'),
        $input = $this.parent().find('.qty__input'),
        $val = $input.val();
      if ($action === '-') {
        if ($val > 1) {
          $input.val($val - 1);
        }
      }
      else {
        $input.val(parseInt($val) + 1);
      }
    });
    //endregion

    if ($('.product-full').length) {
      $('.footer').addClass('footer_product')
    }

    //endregion

    //region КАТАЛОГ
    //region Видео в каталоге
    $(document).on('click', '[data-popup-video]', function () {
      var $this = $(this),
        $videoId = $this.data('video-id'),
        $videoNid = $this.data('target-nid'),
        $modal = $('.modal_video'),
        $modalClose = $modal.find('.modal__close'),
        $modalOverlay = $modal.find('.modal__overlay');

      var $productBlock = $('[data-product-nid="' + $videoNid + '"]'),
        $productTitle = $productBlock.find('[data-product-title]'),
        $productRate = $productBlock.find('[data-product-rate]'),
        $productActions = $productBlock.find('[data-product-actions]'),
        $productFeatures = $productBlock.find('[data-product-features]'),
        $productPrices = $productBlock.find('[data-product-prices]'),
        $productImage = $productBlock.find('[data-product-image] img').attr('src');

      // Закрываем попап с товаром
      $('.modal_product').removeClass('js-active');

      // console.log('$productBlock', $productBlock.html());
      // console.log('$productRate', $productRate.html());
      // console.log('$productBlock', $productBlock);
      // console.log('$videoId', $videoId);
      // console.log('$videoNid', $videoNid);
      // console.log('$modal.find(\'[data-modal-video-player]\')', $modal.find('[data-modal-video-player]').html());
      // console.log('$videoId', $videoId);
      // console.log('$productTitle.text()', $productTitle.text());

      //region Заполняю
      // Видео
      $modal.find('[data-modal-video-player]').html('<video src="https://data.salutarium.ru/video_hd/' + $videoId + '.mp4" autoplay="autoplay" controls="controls" disablepictureinpicture="disablePictureInPicture" controlslist="nodownload"></video>')
      // Изображения
      $modal.find('[data-modal-video-image]').attr('src', $productImage);
      // Заголовок
      $modal.find('[data-modal-video-title]').html($productTitle.text());
      // Рейтинг
      $modal.find('[data-modal-video-rate]').html($productRate.get(0).outerHTML);
      // Лайк и поделиться
      $modal.find('[data-modal-video-actions]').html($productActions.get(0).outerHTML);
      // Характеристики
      $modal.find('[data-modal-video-features]').html($productFeatures.get(0).outerHTML);
      // Цены
      $modal.find('[data-modal-video-prices]').html($productPrices.get(0).outerHTML);
      //endregion

      // Добавить в корзину
      $modal.on('click', '.video-popup__button', function () {
        $(this).addClass('js-active');
        // Клик на реальную кнопку
        $productBlock.find('.node-add-to-cart').click();

        // Цены обновляем
        setTimeout(function () {
          return $modal.find('[data-modal-video-prices]').html($productPrices.get(0).outerHTML)
        }, 2000);
      });

      // Добавить в избранное
      $modal.on('click', '.flag', function () {
        $(this).toggleClass('flag-action').toggleClass('unflag-action');
        // Клик на реальную кнопку
        $productBlock.find('.flag').click();
      });

      // Закрыть попап
      $(document).on('click', $modalClose, function () {
        // Удалить видео
        if ($modal) {
          $modal.find('[data-modal-video-player]').empty();
        }
        // Очистить кэш попапа
        setTimeout(function () {
          return $modal = undefined;
        }, 100);
      });
      $(document).on('click', $modalOverlay, function () {
        // Удалить видео
        if ($modal) {
          $modal.find('[data-modal-video-player]').empty();
        }
        // Очистить кэш попапа
        setTimeout(function () {
          return $modal = undefined;
        }, 100);
      });

      // Показываю попап
      $modal.addClass('js-active');
    });
    //endregion
    //endregion

    //region Закрыть попап
    $(document).on('click', '.modal__close, .modal__overlay', function () {
      $(this).parents('.modal').removeClass('js-active');
    });
    //endregion

    //region Большое изображение товара
    $(document).on('click', '.open-preview', function () {

      var $this = $(this),
        $videoNid = $this.data('target-nid'),
        $modal = $('.modal_image'),
        $modalClose = $modal.find('.modal__close');

      var $productBlock = $('[data-product-nid="' + $videoNid + '"]'),
        $productActions = $productBlock.find('[data-product-actions]'),
        $productPrices = $productBlock.find('[data-product-prices]'),
        $productImage = $this.data('image');

      //region Заполнение попапа
      // Изображение
      $modal.find('img').attr('src', $productImage);
      // В избранное
      $modal.find('[data-modal-video-actions]').html($productActions.get(0).outerHTML);
      // Цены
      $modal.find('[data-modal-video-prices]').html($productPrices.get(0).outerHTML);

      if ($productBlock.find(['data-product-buy']).find('.messages').css('display') === "block") {
        $modal.find('.image-popup__button').addClass('js-active');
      } else {
        $modal.find('.image-popup__button').removeClass('js-active');
      }
      //endregion

      // Добавить в избранное
      $modal.on('click', '.flag', function () {
        $(this).toggleClass('flag-action').toggleClass('unflag-action');
        // Клик на реальную кнопку
        $productBlock.find('.flag').click();
      });

      //region Добавление в корзину
      $modal.on('click', '.image-popup__button', function () {
        $(this).addClass('js-active');
        $productBlock.find('.node-add-to-cart').click();
      });
      //endregion

      $modal.addClass('js-active');

    });
    //endregion


    $('.form-delivery__current-address').on('click', function () {
      $(this).parent().find('.form-delivery__new-address').slideToggle();
    });
    


  });
})(jQuery);

// Установить курсов в конец инпута
function moveCursorToEnd(el) {
  if (typeof el.selectionStart == "number") {
    el.selectionStart = el.selectionEnd = el.value.length;
  }
  else if (typeof el.createTextRange != "undefined") {
    el.focus();
    var range = el.createTextRange();
    range.collapse(false);
    range.select();
  }
}

/****************
 * Modals example
 ****************/
/*
 import {showModal, watchScrollY} from "./modal";

 watchScrollY();

 [...document.querySelectorAll('.modal-link')].map(link => {
 link.addEventListener('click', (e) => {
 e.preventDefault();
 showModal(e.target); // or showModal('#modal') or showModal('.modal') or showModal(document.querySelector('#modal'))
 })
 });*/
/****************
 * Phone mask example
 ****************/

window.addEventListener('load', () => {
  const maskedPhonesInput = document.querySelectorAll('input[type="tel"], .phone-mask');
  if (maskedPhonesInput) {
    const maskOptions = {
      mask: '+{7} (000) 000-00-00',
    };
    [...maskedPhonesInput].map((el) => {
      const maskedInput = IMask(el, maskOptions);
      el.addEventListener('keydown', () => {
        if (maskedInput.unmaskedValue === '78') {
          maskedInput.value = '+7 (';
        }
      });
      // simple validation
      el.addEventListener('keyup', () => {
        if (maskedInput.unmaskedValue.length === 11) {
          maskedInput.el.input.classList.add('input_valid');
          maskedInput.el.input.classList.remove('input_invalid');
        }
        else {
          maskedInput.el.input.classList.add('input_invalid');
          maskedInput.el.input.classList.remove('input_valid');
        }
      });
    });
  }
});


//region Скопировал с исходников вёрстки феерии и чуть с самой феерии. Просто сделай код как на феерии, тут вообще ничего нового не добавлял
/****************
 * Cart
 ****************/
window.addEventListener('load', () => {
  const cart = document.querySelector('.m-cart');
  if (cart) {
    // Счетчик
    const counters = cart.querySelectorAll('.m-cart__table-item input.count');
    [...counters].map(input => {
      const changeValue = (increase) => {
        if (increase && Number(input.value) < 100) {
          input.value = Number(input.value) + 1;
          input.parentNode.parentNode.classList.remove('m-cart__table-item_disable');
        }
        else if (!increase && Number(input.value) > 1) {
          input.value = Number(input.value) - 1;
          input.parentNode.parentNode.classList.remove('m-cart__table-item_disable');
        }
        else if (!increase && Number(input.value) === 1) {
          input.value = 0;
          input.parentNode.parentNode.classList.add('m-cart__table-item_disable');
        }
        return false;
      }
      input.addEventListener('change', () => {
        if (input.value > 99) {
          input.value = 99;
        }
      })
      input.previousElementSibling.addEventListener('click', (e) => {
        e.preventDefault();
        changeValue(false);
      })
      input.nextElementSibling.addEventListener('click', (e) => {
        e.preventDefault();
        changeValue(true);
      })
    });

    // Сворачивание виджетов
    const widgetTitles = cart.querySelectorAll('.m-cart-widget__title');
    [...widgetTitles].map(title => {
      title.parentElement.classList.add('m-cart-widget_minimized');
      title.addEventListener('click', () => {
        title.parentElement.classList.toggle('m-cart-widget_minimized');
      })
    })

    // сортировка виджетов сайдбара
    const sidebar = cart.querySelector('.m-cart__sidebar');
    const widgets = sidebar.querySelectorAll('.m-cart-widget');

    let sidebarLayout = 'desktop';
    const constructMiddleSidebarLayout = () => {
      if (cart.classList.contains('m-cart_checkout')) {
        return false;
      }
      const columns = {
        first: [],
        second: [],
      };
      [...widgets].map((widget, index) => {
        if (index < 2) {
          columns.first.push(widget);
        }
        if (widget.classList.contains('m-cart-widget_summary') || widget.classList.contains('m-cart-widget_bonus')) {
          columns.second.unshift(widget);
        }
        if (index % 2 === 0) {
          columns.first.push(widget);
        }
        else {
          columns.second.push(widget);
        }
      });
      sidebar.innerHTML = '';
      const column1 = document.createElement('div');
      column1.classList.add('m-cart__sidebar-column');
      const column2 = document.createElement('div');
      column2.classList.add('m-cart__sidebar-column');

      columns.first.map(widget => column1.append(widget));
      columns.second.map(widget => column2.append(widget));

      sidebar.append(column1);
      sidebar.append(column2);

      const list = cart.querySelector('.m-cart__list');
      list.after(sidebar);
      sidebarLayout = 'middle';
    }

    const constructMobileSidebarLayout = () => {
      if (cart.classList.contains('m-cart_checkout')) {
        return false;
      }
      sidebar.innerHTML = '';
      [...widgets].map(widget => {
        sidebar.append(widget);
      });
      const list = cart.querySelector('.m-cart__list');
      list.after(sidebar);
      sidebarLayout = 'mobile';
    }

    const returnSidebarLayout = () => {
      if (cart.classList.contains('m-cart_checkout')) {
        return false;
      }
      sidebar.innerHTML = '';
      [...widgets].map(widget => {
        sidebar.append(widget);
      });
      const wrapper = cart.querySelector('.m-cart__content');
      wrapper.after(sidebar);
      sidebarLayout = 'desktop';
    }

    if (window.matchMedia('(max-width: 990px)').matches
      && window.matchMedia('(min-width: 780px)').matches) {
      constructMiddleSidebarLayout();
    }
    if (window.matchMedia('(max-width: 779px)').matches) {
      constructMobileSidebarLayout()
    }

    window.addEventListener('resize', () => {
      if (window.matchMedia('(max-width: 990px)').matches
        && window.matchMedia('(min-width: 780px)').matches
        && sidebarLayout !== 'middle') {
        constructMiddleSidebarLayout();
      }
      if (window.matchMedia('(max-width: 779px)').matches
        && sidebarLayout !== 'mobile') {
        constructMobileSidebarLayout();
      }
      if (window.matchMedia('(min-width: 991px)').matches
        && sidebarLayout !== 'desktop') {
        returnSidebarLayout();
      }
    });
  }
});

/****************
 * Checkout
 ****************/
window.addEventListener('load', () => {
  const checkout = document.querySelector('.m-checkout');
  if (checkout) {

    //region Отмечаем шаги как выполненные
    const forms = checkout.querySelectorAll('.form-contact, .form_legal-data, .form_delivery');
    [...forms].map(form => {
      const requiredInputs = form.querySelectorAll('input[required]');
      [...requiredInputs].map(input => {
        let allFilled = false;
        input.addEventListener('change', () => {
          allFilled = ![...requiredInputs].filter(el => el.value.trim() === '').length;
          if (allFilled) {
            form.classList.add('form_status-ok');
          }
          else {
            form.classList.remove('form_status-ok');
          }
        })
      })
    })
    //endregion

    //region Поднимающиеся label
    const textInputWrappers = checkout.querySelectorAll('.form_contact .form-type-textfield, .form_legal-data .form-type-textfield, .form_delivery .form-type-textfield');
    [...textInputWrappers].map(wrapper => {
      const input = wrapper.querySelector('input');
      input.addEventListener('focus', () => {
        wrapper.classList.add('form-item_filled');
      });
      input.addEventListener('blur', (e) => {
        if (e.target.value.trim() === '') {
          e.target.value = '';
          wrapper.classList.remove('form-item_filled');
          return false;
        }
      });
    })
    //endregion


    const fileInputs = checkout.querySelectorAll('.form-type-file');
    [...fileInputs].map(inputWrapper => {
      const label = inputWrapper.querySelector('label');
      const input = inputWrapper.querySelector('input');

      input.addEventListener('change', (e) => {
        if (e.target.files.length > 0) {
          const filename = e.target.files[0].name;
          label.innerText = filename.length > 20 ? filename.substr(0, 18) + '...' : filename;
          inputWrapper.classList.add('has-attach');
          let delButton;
          if (!label.nextElementSibling.classList.contains('delete-file')) {
            delButton = document.createElement('button');
            delButton.classList.add('delete-file');
            delButton.setAttribute('type', 'button');
            label.after(delButton);
          }
          else {
            delButton = label.nextElementSibling;
          }
          delButton.addEventListener('click', (e) => {
            e.preventDefault();
            inputWrapper.classList.remove('has-attach');
            label.innerText = 'Выберите файл';
            input.value = '';
          })
        }
      })
    })

    //region Скрывать/показывать карту
    // const deliveryForm = checkout.querySelector('.form-delivery');
    // const legend = deliveryForm.querySelector('.form-delivery__address legend');
    // const mapFormItem = deliveryForm.querySelector('.form-delivery__map');
    // const button = document.createElement('span');
    // button.className = "form-delivery__show-map";
    // button.innerText = 'показать карту';
    // button.addEventListener('click', () => {
    //   if (button.innerText === 'показать карту') {
    //     button.innerText = 'скрыть карту';
    //   }
    //   else {
    //     button.innerText = 'показать карту';
    //   }
    //   mapFormItem.classList.toggle('js-hidden')
    // });
    // legend.append(button);
    // mapFormItem.classList.add('js-hidden');
    //endregion


    //region Кастомные селекты
    const selects = checkout.querySelectorAll('select');
    [...selects].map(select => {
      customSelect(select);
    })
    //endregion


    // const calendarDeliveryButton = checkout.querySelector('.m-calendar-button');
    // const calendarDeliveryEl = calendarDeliveryButton.nextElementSibling.querySelector('.m-calendar');
    // const calendarDelivery = new TavoCalendar(calendarDeliveryEl, {
    //   locale: 'ru',
    //   past_select: true
    // });



    const calButtons = checkout.querySelectorAll('.m-calendar-button');

    [...calButtons].map(btn => {
      const calendarEl = btn.nextElementSibling.querySelector('.m-calendar');

      let currentDate = new Date();
      let currentDateFormat = currentDate.toISOString().slice(0, 10);
      let currentDateYear = currentDate.getFullYear();

      // Для дня рождения
      if (calendarEl.classList.contains('m-calendar_birthday')) {
        const birthdayYear = document.querySelector('.form-item-contact-birthdate-year select').value;
        const birthdayMonth = document.querySelector('.form-item-contact-birthdate-month select').value;
        const birthdayDay = document.querySelector('.form-item-contact-birthdate-day select').value;
        if (birthdayYear && birthdayMonth && birthdayDay) {
          currentDateFormat = birthdayYear + '-' + birthdayMonth + '-' + birthdayDay;
        } else {
          currentDateFormat = currentDateYear - 18 + '-01-01';
        }
      }

      const calendar = new TavoCalendar(calendarEl, {
        locale: 'ru',
        past_select: true,
        date: currentDateFormat
      });


      let formSelects = btn.parentElement.parentElement.querySelectorAll('.form-item select');
      // Для дня рождения
      if (calendarEl.classList.contains('m-calendar_birthday')) {
        formSelects = btn.parentElement.parentElement.parentElement.querySelectorAll('.form-item select');
      }

      const getDate = () => {
        const number = formSelects[0].value.length === 2 ? formSelects[0].value : `0${formSelects[0].value}`;
        const month = formSelects[1].value.length === 2 ? formSelects[1].value : `0${formSelects[1].value}`;
        return `${formSelects[2].value}-${month}-${number}`;
      }
      const setDate = (str) => {
        const arr = str.split('-').reverse();

        console.log('clear', arr[1]);
        console.log('dec', parseInt(arr[1],10));

        formSelects[0].customSelect.value = parseInt(arr[0], 10);
        formSelects[1].customSelect.value = parseInt(arr[1], 10);
        formSelects[2].customSelect.value = arr[2];
      }
      calendarEl.addEventListener('calendar-select', () => {
        setDate(calendar.getSelected());
        calendarEl.parentElement.classList.remove('m-calendar-wrapper_visible');
      })
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        btn.nextElementSibling.classList.toggle('m-calendar-wrapper_visible');
      })
    })
  }



  //region ПРОФИЛЬ (ЛИЧНЫЙ КАБИНЕТ)
  const profile = document.querySelector('.profile');
  if (profile) {
    //region Кастомные селекты
    const selects = profile.querySelectorAll('select');
    if (selects) {
      [...selects].map(select => {
        customSelect(select);
      })
    }
    //endregion
  }
  //endregion

})
//endregion